.card {
    width: 100%;
    max-width: inherit;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.card:hover {
    /* transform: translateY(-5px); */
    /* Lift effect on hover */
    /* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); */
    /* Increased shadow on hover */
}

.card-header {
    text-align: center;
    margin-bottom: 20px;
}

.card-header h1 {
    font-size: 2.5rem;
    color: #333;
    font-family: 'Arial', sans-serif;
    border-bottom: 2px solid #000;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.description {
    font-size: 1rem;
    color: #555;
    text-align: center;
    line-height: 1.5;
}

.form-fa {
    width: 50%;
}

.form-button {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.form-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.btn-w {
    width: 100%;
}

@media (min-width: 768px) {
    .btn-w {
        width: 50%;
    }
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}


.form-button:focus {
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}
.fa-auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 20px auto;
}

.qr-code-container {
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.textbox {
    width: 100%;
    padding: 12px;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.textbox:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .card {
        padding: 15px;
    }

    .card-header h1 {
        font-size: 2rem;
    }

    .textbox {
        max-width: 100%;
    }

    .form-button {
        max-width: 100%;
    }
}